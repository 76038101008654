function download(
  uri: string,
  options?: {
    filename?: string;
  },
) {
  const downloadLink = document.createElement("a");
  downloadLink.href = uri;
  downloadLink.download = options?.filename ?? "download";

  document.body.append(downloadLink);
  downloadLink.click();
  downloadLink.remove();
}

export function toCsvDownload(
  data: Record<string, string | number | null | boolean | undefined>[],
  options?: {
    escapeValues?: boolean;
    fileName?: string;
    separator?: string;
  },
) {
  const csv = [
    Object.keys(data[0] ?? {}),
    ...data.map((entry) => Object.values(entry)),
  ]
    .map((line) =>
      line
        .map((value) => {
          if (typeof value === "string" && (options?.escapeValues ?? true))
            return `"${value}"`;

          return value;
        })
        .join(options?.separator ?? ","),
    )
    .join("\n");

  const encoded = encodeURI("data:text/csv;charset=utf-8," + csv);

  return download(encoded, {
    filename: options?.fileName,
  });
}

function normalizeCsvCellValue(value: string) {
  return value.trim().replaceAll(/^"|"$/g, "");
}

export async function parseCsv(file: File) {
  const content = await file.text();

  const [keys, ...lines] = content
    .split("\n")
    .map((l) => l.trim())
    .filter(Boolean);

  return lines.map((line) =>
    line.split(/[,;]/).reduce(
      (obj, value, index) => {
        const key = keys?.split(/[,;]/)[index];

        if (key !== undefined) {
          obj[normalizeCsvCellValue(key)] = normalizeCsvCellValue(value);
        }

        return obj;
      },
      {} as Record<string, string>,
    ),
  );
}
